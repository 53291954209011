<template>
  <div>
    <el-dialog :visible.sync="show">
      <span slot="title">处理维修单</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>单号:</b></label> {{model.SN}}
          </div>
          <div class="form-group">
            <label><b>分类:</b></label> {{model.CAT_NAME}}
          </div>
          <div class="form-group">
            <label><b>位置:</b></label> {{model.LOC_NAME}}
          </div>
          <div class="form-group">
            <label><b>问题描述</b></label>
            <el-input type="textarea" :rows="3" v-model="model.DESCRIPTION" />
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <el-input type="textarea" :rows="2" v-model="model.MEM" />
          </div>
          <div class="form-group">
            <label><b>金额</b></label><br />
            <el-input-number v-model="model.COST" />
          </div>
          <div class="form-group" v-if="model.PIC_NAME">
            <el-image style="width: 300px;"
                :src="model.PIC_URL"
                :preview-src-list="[model.PIC_URL]">
            </el-image>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="show=false">取消</el-button>
        <el-button icon="el-icon-time" type="warning" @click="submit(5)">备修中</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit(7)">完成维修</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
<script>
export default {
  data(){
    return{
      show:false,
      model: {},
    }
  },
  methods:{
    init(model){
      this.show=true;
      this.model=JSON.parse((JSON.stringify(model)));
    },
    submit(sta){
      let self=this;
      this.model.STA=sta;
      this.whale.remote.getResult({
        url:"/api/School/FIX/NoteApi/Update",
        data:self.model,
        completed:function (){
          self.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
