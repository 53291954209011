<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新开维修单</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>名称</b></label><br />
            <el-cascader v-model="cat.val" :options="cat.ls" placeholder="请选择分类"
              :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" />
          </div>
          <div class="form-group">
            <label><b>位置</b></label><br />
            <el-cascader v-model="loc.val" :options="loc.ls" placeholder="请选择位置"
              :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" />
          </div>
          <div class="form-group">
            <label><b>问题描述</b></label>
            <el-input type="textarea" :rows="3" v-model="model.DESCRIPTION" />
          </div>
          <div class="form-group">
            <label><b>照片上传</b> <span style="color: red">(注：仅支持单张图片上传)</span></label>
            <el-upload accept="image/*" action="" :limit="1" list-type="picture-card" :auto-upload="false"
              :on-change="onChange" :file-list="fileList" :on-remove="() => { model.PIC_B64 = '' }">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="form-group" v-if="false">
            <label><b>签名</b></label>
            <sign-canvas v-if="!signUrl" class="sign-canvas" ref="SignCanvas" :options="options" v-model="value" />
            <img v-if="signUrl" class="signImg" :src="signUrl" alt="">
            <div class="btns">
              <el-button type="danger" size="small" @click="canvasClear()" style="margin-right: 20px;">清空</el-button>
              <el-button type="primary" size="small" @click="saveAsImg()">保存</el-button>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style>
.sign-canvas {
  display: block;
  /* margin: 0 auto; */
}

.signImg {
  display: block;
  /* margin: 0 auto; */
  width: 300px;
  height: 120px;
}
.btns{
  margin-top: 5px;
}
</style>
<script>
export default {
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      model: {},
      cat: {
        ls: [],
        val: []
      },
      loc: {
        ls: [],
        val: []
      },
      fileList: [],

      value: '',
      options: {
        lastWriteSpeed: 1, //书写速度 [Number] 可选
        lastWriteWidth: 2, //下笔的宽度 [Number] 可选
        lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]    正方形线帽
        lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        canvasWidth: 300, //canvas宽高 [Number] 可选
        canvasHeight: 120, //高度  [Number] 可选
        isShowBorder: true, //是否显示边框 [可选]
        bgColor: "#fff", //背景色 [String] 可选
        borderWidth: 1, // 网格线宽度  [Number] 可选
        borderColor: "#ccc", //网格颜色  [String] 可选
        writeWidth: 5, //基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: "#101010", // 轨迹颜色  [String] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: "png" //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },
      signUrl: ''
    }
  },
  methods: {
    init() {
      this.sta = { show: true, loading: false }
      this.cat = { ls: this.$parent.cat.ls, val: [] };
      this.loc = { ls: this.$parent.loc.ls, val: [] };
      this.model = {};
      this.fileList = []; //初始化，清空上传列表，无它用
      this.getSign()
    },
    onChange(file) {
      let self = this;
      this.whale.image.compress({
        file: file.raw,
        callback(cf) {
          self.whale.image.toB64({
            file: cf,
            callback(b64) {
              self.model.PIC_B64 = b64;
            }
          })
        }
      })
    },
    // 
    canvasClear() {
      if (this.signUrl) {
        this.signUrl = ''
      } else {
        this.$refs.SignCanvas.canvasClear();
      }
    },
    // 保存图片
    saveAsImg() {
      const img = this.$refs.SignCanvas.saveAsImg();
      console.log(img)
      if (img) {
        const parts = img.split(";base64,");
        console.log("parts", parts);
        const contentType = parts[0].split(":")[1];
        const raw = window.atob(parts[1]);
        console.log("raw", raw);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);

        // 将 Base64 转换为 Uint8Array
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }

        // 创建 Blob 对象
        let blob = new Blob([uInt8Array], { type: contentType });
        console.log(blob);
        this.H5SignUploadPost(blob);
      } else {
        this.$message.warning('没有签名')
      }
    },
    H5SignUploadPost(blob) {
      const formData = new FormData();
      formData.append("file", blob, "sign.png");
      let self = this
      this.whale.remote.uploadSign({
        url: 'api/School/FIX/NoteApi/SignPcUpLoad',
        data: formData,
        completed: function (resp) {
          console.log('sign', resp.Data.DATA.OSS_URL)
          self.signUrl = resp.Data.DATA.OSS_URL
        }
      })
    },
    getSign() {
      let self = this
      self.whale.remote.getResult({
        url: 'api/School/FIX/NoteApi/GetSignPc',
        completed(res) {
          console.log('12', res)
          self.signUrl = res.OSS_URL
        }
      })
    },
    submit() {
      let self = this;
      self.sta.loading = true;
      let n = this.cat.val.length;
      if (n > 0) this.model.CAT_ID = this.cat.val[n - 1];
      n = this.loc.val.length;
      if (n > 0) this.model.LOC_ID = this.loc.val[n - 1];

      this.whale.remote.getResult({
        url: "/api/School/FIX/NoteApi/Save",
        data: self.model,
        finally() { self.sta.loading = false; },
        completed: function () {
          self.sta.show = false;
          self.$parent.grid.sea.PARENT_ID = ''
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
